/* eslint-disable eqeqeq, no-useless-escape, react-hooks/exhaustive-deps */
import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    changeFieldsWithPrefixAndCamelCase,
    getApiRoot,
    getContextRoot,
    getLanguageValue,
    navigateToPath,
    googleRecaptcha,
    getCountriesList,
    getPhoneExtensionsList,
    getMaritalStatusList,
    getEducationList,
    getProfessionList,
    isInactiveField,
    genericGetListFromLanguage,
    notifyClientError
} from "../../common/functions";
import { stringValueValidator } from '../../common/validators';
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import RegisterBottomButtons from "./RegisterBottomButtons";
import GenericFieldComponent from '../GenericFieldComponent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CONSTANTS from '../../common/constants';
import { ACTIONS } from '../../redux/actions';
import {CSS_COLORS} from "../../common/cssColors";


function RegisterStage3Component(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    let initialStateObject = {
        addressLine1: '',
        addressLine2: '',
        apartmentNumber: '',
        city: '',
        stateOrProvince: '',
        country: props.languageKey != 'he' ? 'select' : 'Israel',
        maritalStatus: 'select',
        // fields for married START
        spouseFullname: '',
        spouseNationality: 'select',
        spousePlaceOfBirth: '',
        spouseCountryOfBirth: 'select',
        // fields for married END
        education: 'select',
        profession: 'select',
        jobTitle: 'select',
        placeOfWork: '',
        workAddress: '',
        religion: 'select',
        identifyingMarks: '',
        armyOrMilitaryOrSecurityService: 'select',
        countryServicesOrganization: '',
        countryServicesDesignation: '',
        countryServicesRank: '',
        countryServicesPlaceOfPosting: '',
        phones: [
            {
                telephoneType: 'select',
                countryCode: props.languageKey != 'he' ? 'select' : '+972',
                phoneNumber: '',
                errors: {} // errors
            }
        ],
        fatherName: '',
        fatherCitizenship: 'select',
        fatherPlaceOfBirth: '',
        fatherCountryOfBirth: 'select',
        motherName: '',
        motherCitizenship: 'select',
        motherPlaceOfBirth: '',
        motherCountryOfBirth: 'select',
        areParentsOrGrandparentPakistan: 'select',
        // override with props data
        ...props.stage3Data
    };
    if (!initialStateObject.country) {
        initialStateObject.country = (props.languageKey != 'he' ? 'select' : 'Israel');
    }
    const DEFAULT_SELECT_FIELDS = [ 'maritalStatus', 'education', 'profession', 'jobTitle', 'religion', 'fatherCitizenship', 'fatherCountryOfBirth', 'motherCitizenship', 'motherCountryOfBirth', 'areParentsOrGrandparentPakistan', 'armyOrMilitaryOrSecurityService', 'spouseNationality', 'spouseCountryOfBirth'];
    for (let selectField of DEFAULT_SELECT_FIELDS) {
        if (!initialStateObject[selectField]) {
            initialStateObject[selectField] = 'select';
        }
    }
    if (!lodashGet(initialStateObject, 'phones.length')) {
        initialStateObject.phones = [{
            telephoneType: 'select',
            countryCode: props.languageKey != 'he' ? 'select' : '+972',
            phoneNumber: '',
            errors: {} // errors
        }];
    }

    const [ fieldsData, setFieldsData ] = useState(initialStateObject);
    const [ loaders, setLoaders ] = useState({});
    let [ fieldErrors, setFieldErrors ] = useState({ });

    let PERSONAL_DETAILS_FIELDS = [
        {
            key: 'addressLine1',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => { return stringValueValidator.bind({ languageKey: 'enAndNumeric' })(value); },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.addressLine1 ? ' field-error' : ''),
                    error: fieldErrors.addressLine1,
                    value: fieldsData.addressLine1,
                    inputProps: {
                        name: 'addressLine1'
                    },
                    helperText: fieldErrors.addressLine1 || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'addressLine1'}),
                    label: getLanguageValue('register-individual.stage3-address-line1') + ' *'
                }
            }
        },
        {
            key: 'addressLine2',
            validators: [
                {
                    test: (value) => { if (!value) return true; return stringValueValidator.bind({ languageKey: 'enAndNumeric' })(value); },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'addressLine2 field-component' + (fieldErrors.addressLine2 ? ' field-error' : ''),
                    error: fieldErrors.addressLine2,
                    value: fieldsData.addressLine2,
                    inputProps: {
                        name: 'addressLine2'
                    },
                    helperText: fieldErrors.addressLine2 || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'addressLine2'}),
                    label: getLanguageValue('register-individual.stage3-address-line2')
                }
            }
        },
        {
            key: 'apartmentNumber',
            validators: [
                {
                    test: DEFAULT_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.apartmentNumber ? ' field-error' : ''),
                    error: fieldErrors.apartmentNumber,
                    value: fieldsData.apartmentNumber,
                    inputProps: {
                        name: 'apartmentNumber'
                    },
                    helperText: fieldErrors.apartmentNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'apartmentNumber'}),
                    label: getLanguageValue('register-individual.stage3-apartment-number')
                }
            }
        },
        {
            key: 'country',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'country',
                selectLabel: getLanguageValue('register-individual.stage3-country') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.country ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.country,
                    value: fieldsData.country,
                    inputProps: {
                        name: 'country',
                        id: 'country'
                    },
                    helperText: fieldErrors.country || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        // {
        //     key: 'stateOrProvince',
        //     validators: [
        //         {
        //             test: DEFAULT_HEB_STRING_VALUE_VALIDATOR,
        //             errorMessage: getLanguageValue('register-field-errors-invalid')
        //         }
        //     ],
        //     props: {
        //         type: 'textfield',
        //         fieldProps: {
        //             className: 'field-component' + (fieldErrors.stateOrProvince ? ' field-error' : ''),
        //             error: fieldErrors.stateOrProvince,
        //             value: fieldsData.stateOrProvince,
        //             inputProps: {
        //                 name: 'stateOrProvince'
        //             },
        //             helperText: fieldErrors.stateOrProvince || '',
        //             onChange: DEFAULT_ON_CHANGE,
        //             onBlur: DEFAULT_ON_BLUR.bind({ key: 'stateOrProvince'}),
        //             label: getLanguageValue('register-individual.stage3-state-or-province')
        //         }
        //     }
        // },
        {
            key: 'city',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.city ? ' field-error' : ''),
                    error: fieldErrors.city,
                    value: fieldsData.city,
                    inputProps: {
                        name: 'city'
                    },
                    helperText: fieldErrors.city || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'city'}),
                    label: getLanguageValue('register-individual.stage3-city') + ' *'
                }
            }
        },
        {
            key: 'maritalStatus',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'maritalStatus',
                selectLabel: getLanguageValue('register-individual.stage3-marital-status') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getMaritalStatusList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.maritalStatus ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.maritalStatus,
                    value: fieldsData.maritalStatus,
                    inputProps: {
                        name: 'maritalStatus',
                        id: 'maritalStatus'
                    },
                    helperText: fieldErrors.maritalStatus || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'spouseFullname',
            isHidden: fieldsData.maritalStatus != 'married',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.spouseFullname ? ' field-error' : ''),
                    error: fieldErrors.spouseFullname,
                    value: fieldsData.spouseFullname,
                    inputProps: {
                        name: 'spouseFullname'
                    },
                    helperText: fieldErrors.spouseFullname || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'spouseFullname'}),
                    label: getLanguageValue('register-individual.stage3-spouse-full-name') + ' *'
                }
            }
        },
        {
            key: 'spouseNationality',
            isHidden: fieldsData.maritalStatus != 'married',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'spouseNationality',
                selectLabel: getLanguageValue('register-individual.stage3-spouse-nationality') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-regions-keys-array', 'register-individual.stage3-regions-values-array','key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.spouseNationality ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.spouseNationality,
                    value: fieldsData.spouseNationality,
                    inputProps: {
                        name: 'spouseNationality',
                        id: 'spouseNationality'
                    },
                    helperText: fieldErrors.spouseNationality || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'spousePlaceOfBirth',
            isHidden: fieldsData.maritalStatus != 'married',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.spousePlaceOfBirth ? ' field-error' : ''),
                    error: fieldErrors.spousePlaceOfBirth,
                    value: fieldsData.spousePlaceOfBirth,
                    inputProps: {
                        name: 'spousePlaceOfBirth'
                    },
                    helperText: fieldErrors.spousePlaceOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'spousePlaceOfBirth'}),
                    label: getLanguageValue('register-individual.stage3-spouse-place-of-birth') + ' *'
                }
            }
        },
        {
            key: 'spouseCountryOfBirth',
            isHidden: fieldsData.maritalStatus != 'married',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'spouseCountryOfBirth',
                selectLabel: getLanguageValue('register-individual.stage3-spouse-country-of-birth') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-regions-keys-array', 'register-individual.stage3-regions-values-array','key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.spouseCountryOfBirth ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.spouseCountryOfBirth,
                    value: fieldsData.spouseCountryOfBirth,
                    inputProps: {
                        name: 'spouseCountryOfBirth',
                        id: 'spouseCountryOfBirth'
                    },
                    helperText: fieldErrors.spouseCountryOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'education',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'education',
                selectLabel: getLanguageValue('register-individual.stage3-education') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getEducationList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.education ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.education,
                    value: fieldsData.education,
                    inputProps: {
                        name: 'education',
                        id: 'education'
                    },
                    helperText: fieldErrors.education || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        // {
        //     key: 'profession',
        //     validators: [
        //         {
        //             test: (value) => { if (value == 'select') return false; return true; },
        //             errorMessage: getLanguageValue('register-field-errors-must-select')
        //         }
        //     ],
        //     props: {
        //         type: 'select',
        //         selectId: 'profession',
        //         selectLabel: getLanguageValue('register-individual.stage3-profession') + ' *',
        //         selectOptions: [
        //             { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
        //             ...(getProfessionList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
        //         ],
        //         selectFormClassName: 'field-component' + (fieldErrors.profession ? ' field-error' : ''),
        //         fieldProps: {
        //             native: true,
        //             error: fieldErrors.profession,
        //             value: fieldsData.profession,
        //             inputProps: {
        //                 name: 'profession',
        //                 id: 'profession'
        //             },
        //             helperText: fieldErrors.profession || '',
        //             onChange: DEFAULT_ON_CHANGE
        //         }
        //     }
        // },
        {
            key: 'jobTitle',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'jobTitle',
                selectLabel: getLanguageValue('register-individual.stage3-job-title') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-job-title-keys-array', 'register-individual.stage3-job-title-values-array', 'key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.jobTitle ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.jobTitle,
                    value: fieldsData.jobTitle,
                    inputProps: {
                        name: 'jobTitle',
                        id: 'jobTitle'
                    },
                    helperText: fieldErrors.jobTitle || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'placeOfWork',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.placeOfWork ? ' field-error' : ''),
                    error: fieldErrors.placeOfWork,
                    value: fieldsData.placeOfWork,
                    inputProps: {
                        name: 'placeOfWork'
                    },
                    helperText: fieldErrors.placeOfWork || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'placeOfWork'}),
                    label: getLanguageValue('register-individual.stage3-place-of-work') + ' *'
                }
            }
        },
        {
            key: 'workAddress',
            validators: [
                {
                    test : (value) => { if (!value) return true; return stringValueValidator.bind({ languageKey: 'enAndNumeric' })(value); },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_ADDRESS_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: (value) => {
                        if (!value.trim()) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.workAddress ? ' field-error' : ''),
                    error: fieldErrors.workAddress,
                    value: fieldsData.workAddress,
                    inputProps: {
                        name: 'workAddress'
                    },
                    helperText: fieldErrors.workAddress || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'workAddress'}),
                    label: getLanguageValue('register-individual.stage3-work-address') + ' *'
                }
            }
        },
        {
            key: 'religion',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'religion',
                selectLabel: getLanguageValue('register-individual.stage3-religion') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-religion-keys-array', 'register-individual.stage3-religion-values-array', 'key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.religion ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.religion,
                    value: fieldsData.religion,
                    inputProps: {
                        name: 'religion',
                        id: 'religion'
                    },
                    helperText: fieldErrors.religion || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'identifyingMarks',
            validators: [
                {
                    test: (value) => {
                        if (!value.trim()) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => {
                        if (value.length > 35) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-too-long')
                },
                {
                    test: (value) => {
                        if (!/^([a-zA-Z\s]*)$/g.test(value)) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.identifyingMarks ? ' field-error' : ''),
                    error: fieldErrors.identifyingMarks,
                    value: fieldsData.identifyingMarks,
                    inputProps: {
                        name: 'identifyingMarks'
                    },
                    helperText: fieldErrors.identifyingMarks || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'identifyingMarks'}),
                    label: getLanguageValue('register-individual.stage3-identifying-marks') + ' *'
                }
            }
        },
        {
            key: 'armyOrMilitaryOrSecurityService',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'armyOrMilitaryOrSecurityService',
                selectLabel: getLanguageValue('register-individual.stage3-army-or-military-or-security-service') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    { value: 'true', displayText: getLanguageValue('register-individual.stage2-yes') },
                    { value: 'false', displayText: getLanguageValue('register-individual.stage2-no') }

                ],
                selectFormClassName: 'field-component' + (fieldErrors.armyOrMilitaryOrSecurityService ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.armyOrMilitaryOrSecurityService,
                    value: fieldsData.armyOrMilitaryOrSecurityService,
                    inputProps: {
                        name: 'armyOrMilitaryOrSecurityService',
                        id: 'armyOrMilitaryOrSecurityService'
                    },
                    helperText: fieldErrors.armyOrMilitaryOrSecurityService || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'countryServicesOrganization',
            isHidden: fieldsData.armyOrMilitaryOrSecurityService != 'true',
            validators: [
                {
                    test: (value) => {
                        if (!value.trim()) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.countryServicesOrganization ? ' field-error' : ''),
                    error: fieldErrors.countryServicesOrganization,
                    value: fieldsData.countryServicesOrganization,
                    inputProps: {
                        name: 'countryServicesOrganization'
                    },
                    helperText: fieldErrors.countryServicesOrganization || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'countryServicesOrganization'}),
                    label: getLanguageValue('register-individual.stage3-country-services-organization') + ' *'
                }
            }
        },
        {
            key: 'countryServicesDesignation',
            isHidden: fieldsData.armyOrMilitaryOrSecurityService != 'true',
            validators: [
                {
                    test: (value) => {
                        if (!value.trim()) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.countryServicesDesignation ? ' field-error' : ''),
                    error: fieldErrors.countryServicesDesignation,
                    value: fieldsData.countryServicesDesignation,
                    inputProps: {
                        name: 'countryServicesDesignation'
                    },
                    helperText: fieldErrors.countryServicesDesignation || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'countryServicesDesignation'}),
                    label: getLanguageValue('register-individual.stage3-country-services-designation') + ' *'
                }
            }
        },
        {
            key: 'countryServicesRank',
            isHidden: fieldsData.armyOrMilitaryOrSecurityService != 'true',
            validators: [
                {
                    test: (value) => {
                        if (!value.trim()) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.countryServicesRank ? ' field-error' : ''),
                    error: fieldErrors.countryServicesRank,
                    value: fieldsData.countryServicesRank,
                    inputProps: {
                        name: 'countryServicesRank'
                    },
                    helperText: fieldErrors.countryServicesRank || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'countryServicesRank'}),
                    label: getLanguageValue('register-individual.stage3-country-services-rank') + ' *'
                }
            }
        },
        {
            key: 'countryServicesPlaceOfPosting',
            isHidden: fieldsData.armyOrMilitaryOrSecurityService != 'true',
            validators: [
                {
                    test: (value) => {
                        if (!value.trim()) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.countryServicesPlaceOfPosting ? ' field-error' : ''),
                    error: fieldErrors.countryServicesPlaceOfPosting,
                    value: fieldsData.countryServicesPlaceOfPosting,
                    inputProps: {
                        name: 'countryServicesPlaceOfPosting'
                    },
                    helperText: fieldErrors.countryServicesPlaceOfPosting || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'countryServicesPlaceOfPosting'}),
                    label: getLanguageValue('register-individual.stage3-country-services-place-of-posting') + ' *'
                }
            }
        }
    ];

    let PARENTS_AND_FAMILY_DETAILS_FIELDS = [
        {
            key: 'fatherName',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.fatherName ? ' field-error' : ''),
                    error: fieldErrors.fatherName,
                    value: fieldsData.fatherName,
                    inputProps: {
                        name: 'fatherName'
                    },
                    helperText: fieldErrors.fatherName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'fatherName'}),
                    label: getLanguageValue('register-individual.stage3-father-name') + ' *'
                }
            }
        },
        {
            key: 'fatherCitizenship',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'fatherCitizenship',
                selectLabel: getLanguageValue('register-individual.stage3-father-citizenship') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-regions-keys-array', 'register-individual.stage3-regions-values-array','key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.fatherCitizenship ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.fatherCitizenship,
                    value: fieldsData.fatherCitizenship,
                    inputProps: {
                        name: 'fatherCitizenship',
                        id: 'fatherCitizenship'
                    },
                    helperText: fieldErrors.fatherCitizenship || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'fatherPlaceOfBirth',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.fatherPlaceOfBirth ? ' field-error' : ''),
                    error: fieldErrors.fatherPlaceOfBirth,
                    value: fieldsData.fatherPlaceOfBirth,
                    inputProps: {
                        name: 'fatherPlaceOfBirth'
                    },
                    helperText: fieldErrors.fatherPlaceOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'fatherPlaceOfBirth'}),
                    label: getLanguageValue('register-individual.stage3-father-place-of-birth') + ' *'
                }
            }
        },
        {
            key: 'fatherCountryOfBirth',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'fatherCountryOfBirth',
                selectLabel: getLanguageValue('register-individual.stage3-father-country-of-birth') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-regions-keys-array', 'register-individual.stage3-regions-values-array','key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.fatherCountryOfBirth ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.fatherCountryOfBirth,
                    value: fieldsData.fatherCountryOfBirth,
                    inputProps: {
                        name: 'fatherCountryOfBirth',
                        id: 'fatherCountryOfBirth'
                    },
                    helperText: fieldErrors.fatherCountryOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'motherName',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.motherName ? ' field-error' : ''),
                    error: fieldErrors.motherName,
                    value: fieldsData.motherName,
                    inputProps: {
                        name: 'motherName'
                    },
                    helperText: fieldErrors.motherName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'motherName'}),
                    label: getLanguageValue('register-individual.stage3-mother-name') + ' *'
                }
            }
        },
        {
            key: 'motherCitizenship',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'motherCitizenship',
                selectLabel: getLanguageValue('register-individual.stage3-mother-citizenship') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-regions-keys-array', 'register-individual.stage3-regions-values-array','key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.motherCitizenship ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.motherCitizenship,
                    value: fieldsData.motherCitizenship,
                    inputProps: {
                        name: 'motherCitizenship',
                        id: 'motherCitizenship'
                    },
                    helperText: fieldErrors.motherCitizenship || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'motherPlaceOfBirth',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.motherPlaceOfBirth ? ' field-error' : ''),
                    error: fieldErrors.motherPlaceOfBirth,
                    value: fieldsData.motherPlaceOfBirth,
                    inputProps: {
                        name: 'motherPlaceOfBirth'
                    },
                    helperText: fieldErrors.motherPlaceOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'motherPlaceOfBirth'}),
                    label: getLanguageValue('register-individual.stage3-mother-place-of-birth') + ' *'
                }
            }
        },
        {
            key: 'motherCountryOfBirth',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'motherCountryOfBirth',
                selectLabel: getLanguageValue('register-individual.stage3-mother-country-of-birth') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(genericGetListFromLanguage('register-individual.stage3-regions-keys-array', 'register-individual.stage3-regions-values-array','key', 'value').map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.motherCountryOfBirth ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.motherCountryOfBirth,
                    value: fieldsData.motherCountryOfBirth,
                    inputProps: {
                        name: 'motherCountryOfBirth',
                        id: 'motherCountryOfBirth'
                    },
                    helperText: fieldErrors.motherCountryOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        }
    ];

    // filter relevant only
    PERSONAL_DETAILS_FIELDS = PERSONAL_DETAILS_FIELDS.filter(item => !isInactiveField('stage3', item.key) && !item.isHidden);
    PARENTS_AND_FAMILY_DETAILS_FIELDS = PARENTS_AND_FAMILY_DETAILS_FIELDS.filter(item => !isInactiveField('stage3', item.key));

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([a-zA-Z\"\'\.\-,\s]*)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ADDRESS_VALIDATOR(value) {
        if (!/^([a-zA-Z0-9\-,\s]*)$/g.test(value)) {
            if (!/^([\u0590-\u05FF0-9,\s]*)$/g.test(value)) {
                return false;
            }
        }
        return true;
    }

    function DEFAULT_NUMERIC_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (value && !/^[0-9]+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_ON_BLUR() {
        let matchedField = PERSONAL_DETAILS_FIELDS.find((item) => item.key == this.key);
        if (lodashGet(matchedField, 'validators')) {
            let fieldValue = lodashGet(matchedField, 'props.fieldProps.value');
            for (let validatorObj of lodashGet(matchedField, 'validators')) {
                if (!validatorObj.test(fieldValue)) {
                    notifyClientError({ data: fieldsData, errors: { ...fieldErrors, [this.key] : validatorObj.errorMessage } });
                    setFieldErrors({ ...fieldErrors, [this.key] : validatorObj.errorMessage });
                    return;
                }
            }
        }
    }

    const addPhoneButton = <div className="add-button-container">
        <Button
            disabled={!areAllPhoneValuesOk()}
            onClick={() => {
                fieldsData.phones.push({
                    telephoneType: 'select',
                    countryCode: props.languageKey != 'he' ? 'select' : '+972',
                    phoneNumber: '',
                    errors: {}
                });
                setFieldsData({ ...fieldsData });
            }}>
            <AddCircleIcon />{getLanguageValue('register-individual.stage3-add-another')}
        </Button>
    </div>;

    const deletePhoneButton = (props) => (<div className="delete-button-container">
        <Button onClick={(e) => {
            if (props.itemIndex != null) {
                fieldsData.phones.splice(props.itemIndex, 1);
                setFieldsData({ ...fieldsData });
            }
        }}>
            <RemoveCircleIcon />{getLanguageValue('register-individual.stage3-remove')}
        </Button>
    </div>);

    useEffect(() => {
        actions.setActiveStage(3);
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    return <RegisterStage3Style>
        <div className={"form-container"}>
            <h1>{getLanguageValue('register-individual.stage3-enter-contact-information')}</h1>
            <div className="form-information-container">
                <span className="bold-text important-text">{getLanguageValue('register-individual.stage3-provide-responses-in-language')}</span>
                <br/>
                <span className="bold-text field-warning-color">{getLanguageValue('register-individual.stage3-required-fields-with-asterisk')}</span>
            </div>
            <h2>
                {getLanguageValue('register-individual.stage3-personal-information')}
            </h2>
            <Grid item className={"form-fields-container"} md={12}>
                <Grid container className={"fields-container"}>
                    {PERSONAL_DETAILS_FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>
            <div className={"phones-container"}>
                {fieldsData.phones.map(function(phoneItem, phoneItemIndex) {
                    return <Grid container className={'phones-list-item-row' + (phoneItemIndex % 2 == 0 ? ' even' : ' odd')} key={phoneItemIndex}>
                        <Grid item className={"single-field-container"} md={4}>
                            <GenericFieldComponent
                                type={"select"}
                                selectLabel={getLanguageValue('register-individual.stage3-phone-type') + ' *'}
                                selectId={'telephoneType' + phoneItemIndex}
                                selectOptions={[
                                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage3-select') },
                                    { value: 'home', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-home') },
                                    { value: 'work', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-work') },
                                    { value: 'mobile', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-mobile') },
                                    { value: 'other', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-other') }
                                ]}
                                selectFormClassName={'field-component'}
                                fieldProps={{
                                    native: true,
                                    error: lodashGet(phoneItem, 'errors.telephoneType'),
                                    value: phoneItem.telephoneType,
                                    inputProps: {
                                        name: 'telephoneType',
                                        id: 'telephoneType' + phoneItemIndex
                                    },
                                    helperText: lodashGet(phoneItem, 'errors.telephoneType') || '',
                                    onChange: (e) => { fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], telephoneType: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, telephoneType: false } }; setFieldsData({...fieldsData }); }
                                }}
                            />
                        </Grid>
                        <Grid item className={"single-field-container"} md={4}>
                            <GenericFieldComponent
                                type={'select'}
                                selectId={'countryCode' + phoneItemIndex}
                                selectLabel={getLanguageValue('register-individual.stage3-phone-country-code') + ' *'}
                                selectOptions={[
                                { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                ...(getPhoneExtensionsList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                                ]}
                                selectFormClassName={'field-component two-fields-component bottom-margin'}
                                fieldProps={{
                                    native: true,
                                    error: lodashGet(phoneItem, 'errors.countryCode'),
                                    value: phoneItem.countryCode,
                                    inputProps: {
                                        name: 'countryCode' + phoneItemIndex,
                                        id: 'countryCode' + phoneItemIndex
                                    },
                                    helperText: lodashGet(phoneItem, 'errors.countryCode') || '',
                                    onChange: (e) => { fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], countryCode: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, countryCode: false } }; setFieldsData({...fieldsData }); },
                                }}
                            />
                            <GenericFieldComponent
                                type={"textfield"}
                                fieldProps={{
                                    className: 'field-component two-fields-component',
                                    error: lodashGet(phoneItem, 'errors.phoneNumber'),
                                    value: phoneItem.phoneNumber,
                                    inputProps: {
                                        name: 'phoneNumber'
                                    },
                                    helperText: lodashGet(phoneItem, 'errors.phoneNumber') || '',
                                    onChange: (e) => { fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], phoneNumber: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, phoneNumber: false } }; setFieldsData({...fieldsData }); },
                                    onBlur: (e) => {
                                        const PLAIN_NUMBER_REGEX = /^[0-9]+$/g;
                                        if (!PLAIN_NUMBER_REGEX.test(phoneItem.phoneNumber)) {
                                            fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], phoneNumber: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, phoneNumber: (!phoneItem.phoneNumber.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid')) } };
                                            notifyClientError({ data: fieldsData, errors: { ...fieldErrors } });
                                            setFieldsData({...fieldsData });
                                        }
                                    },
                                    label: getLanguageValue('register-individual.stage3-phone-number') + ' *'
                                }}
                            />
                        </Grid>
                        <Grid item className={"single-field-container"} md={4}>
                            {getDisplayedActionForPhoneNumber(phoneItem, phoneItemIndex)}
                        </Grid>
                    </Grid>;
                })}
                {fieldsData.phones.length > 1 && <div>{addPhoneButton}</div>}
            </div>
            <h2>
                {getLanguageValue('register-individual.stage3-parents-and-family-information')}
            </h2>
            <Grid item className={"form-fields-container"} md={12}>
                <Grid container className={"fields-container"}>
                    {PARENTS_AND_FAMILY_DETAILS_FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>
            <Grid item className={"form-fields-container"} md={12}>
                <Grid container className={'fields-container'}>
                    <Grid item className={"single-field-container"} md={4}>
                        <GenericFieldComponent
                            type={'select'}
                            selectId={'areParentsOrGrandparentPakistan'}
                            selectLabel={getLanguageValue('register-individual.stage3-are-parent-or-grandparents-from-pakistan') + ' *'}
                            selectOptions={[
                                { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                { value: 'true', displayText: getLanguageValue('register-individual.stage2-yes') },
                                { value: 'false', displayText: getLanguageValue('register-individual.stage2-no') },
                            ]}
                            selectFormClassName={'field-component bottom-margin' + (fieldErrors.areParentsOrGrandparentPakistan ? ' field-error' : '')}
                            fieldProps={{
                                native: true,
                                error: fieldErrors.areParentsOrGrandparentPakistan,
                                value: fieldsData.areParentsOrGrandparentPakistan,
                                inputProps: {
                                    name: 'areParentsOrGrandparentPakistan',
                                    id: 'areParentsOrGrandparentPakistan'
                                },
                                helperText: fieldErrors.areParentsOrGrandparentPakistan || '',
                                onChange: (e) => { fieldErrors.areParentsOrGrandparentPakistan = false; setFieldErrors({ ...fieldErrors }); setFieldsData({...fieldsData, areParentsOrGrandparentPakistan: e.target.value }); },
                            }}
                        />
                        <br/><br/>
                        <div className={'important-notice'} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage3-are-parent-or-grandparents-from-pakistan-notice')}} />
                    </Grid>
                </Grid>

            </Grid>
            <RegisterBottomButtons
                actions={{
                    loaders,
                    isNextDisabled,
                    next,
                    back
                }}
            />
            {fieldErrors.submit ? <div className={"submit-error"}>{fieldErrors.submit}</div> : null}
        </div>
    </RegisterStage3Style>;

    function getDisplayedActionForPhoneNumber(item, itemIndex) {
        if (itemIndex === 0) {
            if (fieldsData.phones.length === 1) {
                // 1 item - use add button
                return addPhoneButton;
            }
            else {
                // more than 1 item - use delete button
                return deletePhoneButton({ itemIndex });
            }
        }
        else {
            // use delete button
            return deletePhoneButton({ itemIndex });
        }
    }

    function areAllPhoneValuesOk() {
        for (let loop = 0 ; loop < fieldsData.phones.length ; ++loop) {
            const item = fieldsData.phones[loop];
            if (item.telephoneType == 'select' || !item.countryCode || !item.phoneNumber) {
                return false;
            }
        }
        return true;
    }

    function isNextDisabled() {
        return false;
    }

    async function next() {
        // goto next stage
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            notifyClientError({ data: fieldsData, errors: fieldErrors });
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (loaders.next) {
            return;
        }
        if (actions && actions.nextStage) {
            try {
                setLoaders({ ...loaders, next: true });
                let duplicateData = JSON.parse(JSON.stringify(fieldsData));
                // remove leading zeroes of phones
                for (let phoneItem of duplicateData.phones) {
                    delete phoneItem.errors;
                    phoneItem.phoneNumber = String(parseInt(phoneItem.phoneNumber));
                }
                let duplicateDataForState = JSON.parse(JSON.stringify(fieldsData));
                duplicateData.areParentsOrGrandparentPakistan = duplicateData.areParentsOrGrandparentPakistan == 'true' ? '1' : '0';
                duplicateData.armyOrMilitaryOrSecurityService = duplicateData.armyOrMilitaryOrSecurityService == 'true' ? '1' : '0';
                let url = getApiRoot() + 'update-process/?type=individual&languageKey=' + props.languageKey;
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage3'),
                        stage: "3",
                        key: props.individualKey
                    })
                };
                let result = await (await fetch(url, options)).json();
                if (!lodashGet(result, 'data.success')) {
                    setLoaders({ ...loaders, next: false });
                    setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
                    return;
                }
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'registerIndividualStagesData.stage3Data',
                        value: duplicateDataForState
                    }]
                });
                actions.nextStage();
                return;
            }
            catch (err) {
                // nothing to do
                setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
            }
            setLoaders({ ...loaders, next: false });
        }
    }

    function back() {
        setTimeout(() => {
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage2');
        }, 10);
    }

    function validate(config) {
        let errors = [];

        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        for (let fieldItem of PERSONAL_DETAILS_FIELDS.concat(PARENTS_AND_FAMILY_DETAILS_FIELDS)) {
            let fieldValue = fieldsData[fieldItem.key];
            let fieldValidators = fieldItem.validators;
            if (fieldValidators) {
                for (let fieldValidatorItem of fieldValidators) {
                    if (!fieldValidatorItem.test(fieldValue)) {
                        errors.push(fieldItem.key);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldItem.key] = fieldValidatorItem.errorMessage;
                        }
                        break;
                    }
                }
            }
        }
        if (fieldsData.areParentsOrGrandparentPakistan == 'select' || fieldsData.areParentsOrGrandparentPakistan == 'true') {
            errors.push('areParentsOrGrandparentPakistan');
            if (lodashGet(config, 'setErrors')) {
                const errorMessagesMapping = {
                    select: 'register-field-errors-must-select',
                    true: 'register-individual.stage3-are-parent-or-grandparents-from-pakistan-yes-error'
                };
                fieldErrors.areParentsOrGrandparentPakistan = getLanguageValue(errorMessagesMapping[fieldsData.areParentsOrGrandparentPakistan]);
            }
        }

        let changedFieldsData = false;
        if (Array.isArray(fieldsData.phones)) {
            let index = 0;
            for (let phoneItem of fieldsData.phones) {
                let PLAIN_NUMBER_REGEX = /^[0-9]+$/g;
                let COUNTRY_CODE_REGEX = /^[\+]{0,1}[0-9]+$/g;
                if (lodashGet(config, 'setErrors')) {
                    changedFieldsData = true;
                    phoneItem.errors = {};
                }
                if (phoneItem.telephoneType == 'select') {
                    errors.push('phones_telephoneType' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        phoneItem.errors['telephoneType'] = getLanguageValue('register-field-errors-must-select');
                    }
                }
                if (!COUNTRY_CODE_REGEX.test(phoneItem.countryCode)) {
                    errors.push('phones_countryCode' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        phoneItem.errors['countryCode'] = !phoneItem.countryCode.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
                    }
                }
                if (!PLAIN_NUMBER_REGEX.test(phoneItem.phoneNumber)) {
                    errors.push('phones_phoneNumber' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        phoneItem.errors['phoneNumber'] = !phoneItem.phoneNumber.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
                    }
                }
                ++index;
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
            if (changedFieldsData) {
                setFieldsData(fieldsData);
            }
        }
        return errors;
    }
}

const RegisterStage3Style = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .single-field-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
    &.addressLine2 label {
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
        font-size: 13px;
      }
    }
  }
  .phones-list-item-row {
    border: 1px solid black;
    padding: 10px;
    &.even {
      background-color: #eee;
    }
  }
  .two-fields-component {
    width: calc(50% - 40px);
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: calc(100% - 20px);
      &.bottom-margin {
        margin-bottom: 20px;
      }
    }
  }
  .important-notice {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .add-button-container, .delete-button-container {
    margin-top: 15px;
    button:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .delete-button-container {
    svg {
      color: #A00000;
    }
  }
  .submit-error {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;

const RegisterStage3 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage3Data: lodashGet(state, 'registerIndividualStagesData.stage3Data')
    }),
    {})(RegisterStage3Component);

export default RegisterStage3;
